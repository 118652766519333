.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
}

.navbar-logo img {
  width: 160px;
  height: auto;
}

.navbar-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  /* background-color: red; */
}

.navbar-menu-icon {
  display: none;
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

.navbar-links li {
  margin-left: 2rem;
  margin-right: 2.5rem;

  text-decoration: none;
  /* color: white; */
  /* font-family: sans-serif; */
  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
}

/* .navbar-links li:hover {
    text-decoration: underline;
  } */

.navbarLink {
  text-decoration: none;
  color: #131f67;
  font-size: 0.9rem;
  /* font-size: 13.5px; */

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  /* text-decoration: underline; */
}

.navbarLinkDonate {
  text-decoration: none;
  /* color: #131f67; */

  font-family: "sweet-sans-pro", sans-serif;
  font-weight: 600;
  font-style: normal;

  background-color: #f6785c;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1.9rem;
  font-size: 0.9rem;

  cursor: pointer;
  /* text-decoration: underline; */
}

.navbarLink:hover {
  text-decoration: underline;
}

.navbarLinkImg {
  /* margin-left: 1rem; */
  position: relative;
  left: 15px;
  width: 7%;
  top: 2px;
}

.donateButton {
  text-decoration: none;
  background-color: #f6785c;
  color: #fff;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 1.9rem;
  font-size: 0.9rem;

  font-weight: 600;
  cursor: pointer;
}

.donateButton:hover {
  text-decoration: underline;
}

.navbar-links li:first-child {
  margin-left: 0;
}

@media screen and (max-width: 850px) {
  .navbar-links li {
    margin-left: 2rem;
    margin-right: 0.9rem;
  }
}

/* NAVBAR MEDIA QUERIES */

@media screen and (max-width: 810px) {
  .hero.show {
    margin-top: calc(60vh - 100px);
    animation: slide-down 0.3s;
  }

  .hero.hide {
    margin-top: 8%;
    animation: slide-up-hero 0.3s;
  }

  @keyframes slide-up-hero {
    from {
      transform: translateY(30%);
    }
    to {
      transform: translateY(0);
    }
  }

  .navbar {
    justify-content: space-between;
  }

  .navbar-menu-icon {
    display: block;
    cursor: pointer;
  }

  .navbar-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 120px;
    left: 0;
    right: 0;
    padding: 10px 0;
    transition: all 0.3s ease-in-out;
    /* opacity: 0; */
  }

  .navbar-links.show {
    display: flex;
    animation: slide-down 0.3s;
  }

  @keyframes slide-down {
    from {
      transform: translateY(-30%);
    }
    to {
      transform: translateY(0);
    }
  }

  .navbar-links.hide {
    /* display: none; */
    animation: slide-up 0.3s;
  }

  @keyframes slide-up {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-30%);
    }
  }

  .navbar-links li {
    padding: 10px;
    border-top: 1px solid #fff;
    margin-left: 0;
  }

  .section1 {
    margin-top: 15%;
  }
}

@media screen and (max-width: 515px) {
  .hero.show {
    margin-top: 67%;

    animation: slide-down 0.3s;
  }
}
